// 3rd Party Libraries
@use 'slick-carousel/slick/slick' as *;
@use 'slick-carousel/slick/slick-theme' as *;

* {
  font-family: 'Montserrat', 'Helvetica Neue', Helvetica, arial, sans-serif;
  -webkit-tap-highlight-color: rgba($black, 0);
}

body {
  background-color: #e9e9e9;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  &.noscroll {
    overflow: hidden;
  }
}

h1 {
  box-sizing: border-box;
}

.page {
  min-width: 320px;
  overflow: auto;
}

.page-wrapper {
  padding: 2rem 6rem;
}

.hide {
  display: none;
}

// theme colors
$PrimaryColor: #06a1e0 !default;
$SecondaryColor: $gray47 !default;
$PrimaryAction: $orange1 !default;

// form field defaults
$FieldHeight: 50px !default;
$FieldFontSize: 1.2rem !default;
$FieldBorderRadius: 5px !default;

// button defaults
$ButtonHeight: 55px !default;
$ButtonFontSize: 1.6rem !default;
$ButtonBorderRadius: 5px !default;

// common imports
@import '../funcs';
@import '../globals';

// animations
@import 'animations/_index';

// base theme pages
@import 'pages/_index';

// base theme components
@import 'components/buttons';
@import 'components/column-grid';
@import 'components/error-messages';
@import 'components/form';
@import 'components/modals/_index';
@import 'components/messages';
@import 'components/tablediv';
@import 'components/tooltip';

@include mobile {
  .page-wrapper {
    padding: 1.25rem 2.5rem;
  }
}

@include large-screens {
  html,
  body {
    font-size: 20px;
  }
}
