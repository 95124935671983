$HeaderLogoWidth: 150px !default;
$HeaderLogoHeight: auto !default;

header {
  background-color: $white;
  box-sizing: border-box;
  box-shadow: 0 1px 2px $gray;
  display: flex;
  padding: 20px 10px;
  position: relative;
  z-index: 12;
  .logo {
    align-self: center;
    flex: 1;
    svg,
    img {
      height: $HeaderLogoHeight;
      max-width: 200px;
      max-height: 60px;
    }
  }
}

@include mobile {
  header {
    .download {
      display: none;
    }
  }
}

@include ie11 {
  header {
    @include ieFlexFix();
    .logo,
    .download {
      @include ieFlexFix();
      flex: 1 1 auto;
      .button {
        span {
          &:before {
            font-size: 1.4rem;
            margin-right: 0.4rem;
          }
        }
      }
    }
  }
}
