.app-selector {
  overflow-x: hidden;

  .page-title-bar {
    display: flex;
    justify-content: space-between;
    margin: 2rem 0;

    h1 {
      margin: 0;
    }

    .action-bar {
      margin: 0;
    }
  }

  .application-questions {
    .field {
      align-self: flex-end;
      box-sizing: border-box;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      @include col-width(4);
    }
  }

  .results {
    .title {
      font-size: 2rem;
      font-weight: bold;
    }
  }
}

@include tablet {
  .app-selector {
    .application-questions {
      .field {
        @include col-width(6);
      }
    }
  }
}

@include small-tablet {
  .app-selector {
    .page-title-bar {
      display: block;

      .btn {
        margin: 1rem 0;
      }
    }
  }
}

@include mobile {
  .app-selector {
    .application-questions {
      .field {
        @include col-width(12);
      }
    }
  }
}
