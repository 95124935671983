// Zoom Action
$ModalZoomTransitionTiming: cubic-bezier(0.68, -0.55, 0.27, 1.55) !default;
.modalZoom-enter {
  &.modalZoom-enter-active {
    .modal-window {
      opacity: 1;
      transform: scale(1);
      transition: opacity 200ms ease-in, transform 500ms $ModalZoomTransitionTiming;
    }
  }
  .modal-window {
    opacity: 0;
    transform: scale(0.7);
  }
}
.modalZoom-exit {
  &.modalZoom-exit-active {
    .modal-window {
      opacity: 0;
      transform: scale(0.1);
      transition: opacity 500ms ease-out, transform 500ms $ModalZoomTransitionTiming;
    }
  }
  .modal-window {
    opacity: 1;
    transform: scale(1);
  }
}

.modal-container {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  .modal {
    $MaxWidth: 98vw;
    display: block;
    height: auto;
    left: 50%;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    width: $MaxWidth;
    z-index: 21;
    &.max800 {
      max-width: 800px;
      width: 100%;
    }
    &.max600 {
      max-width: 600px;
      width: 100%;
    }
    &.max500 {
      max-width: 500px;
      width: 100%;
    }
    &.max400 {
      max-width: 400px;
      width: 100%;
    }
    .modal-window {
      background-color: $white;
      border: 1px solid $black;
      box-shadow: 0 0 16px -3px $sgidarkgray;
      .modal-close-bar {
        display: block;
        overflow: hidden;
        background-color: $gainsboro;
        .modal-close-button {
          cursor: pointer;
          float: right;
          padding: 5px 15px;
          text-align: center;
          z-index: 2;
          &:before {
            @include icon('\f00d');
            color: $black;
            font-size: 20px;
            line-height: 8px;
          }
        }
      }
      .modal-body {
        $MaxHeight: 94vh;
        background-color: $white;
        height: auto;
        max-height: $MaxHeight;
        overflow: auto;
        position: relative;
        .video-responsive {
          background: $black;
          height: 0;
          margin: 0 auto;
          overflow: hidden;
          padding-bottom: $MaxHeight;
          width: 100%;
          .offline-video,
          .online-video {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 0;
          }
        }
      }
    }
  }
  .modal-overlay {
    @include opacity(50);
    background-color: $black;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 20;
  }
}

@include small-tablet {
  .modal-container {
    .modal {
      &.max800,
      &.max600,
      &.max500,
      &.max400 {
        width: 96%;
      }
    }
  }
}
