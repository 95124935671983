.login-page {
  align-items: center;
  // background-color: $gray23;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  right: 0;
  position: fixed;
  top: 0;
  .login-card {
    background-color: $white;
    border-radius: 3px;
    box-sizing: border-box;
    box-shadow: 4px 4px 12px rgba($black, 0.5);
    max-width: 500px;
    padding: 20px;
    width: 90%;
    .btn.primary {
      span {
        &:before {
          font-weight: 500;
          margin-right: 5px;
          @include icon('\f090');
        }
      }
    }
    .forgot-password {
      margin-top: 20px;
      .fp-text {
        margin-right: 5px;
      }
      &:after {
        content: '';
        clear: both;
        display: block;
        float: none;
      }
    }
  }
}
