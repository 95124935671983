%item-image {
  align-items: center;
  backface-visibility: hidden;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  background: #f2f2f2;
  background: radial-gradient(ellipse at center, rgba($black, 0.1) 48%, rgba($black, 0.4) 100%);
  display: flex;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80f2f2f2', endColorstr='#898a8c', GradientType=1 );
  min-height: 150px;
  justify-content: center;
  overflow: hidden;
  .no-backgroundblendmode & {
    background: $white;
  }
  &:hover {
    img {
      @include opacity(100);
      transform: scale(1.1);
    }
  }
  .category-image {
    align-items: center;
    display: flex;
    backface-visibility: hidden;
    mix-blend-mode: multiply;
    img {
      backface-visibility: hidden;
      display: block;
      height: auto;
      margin: auto;
      max-width: 100%;
      max-height: 100%;
      mix-blend-mode: multiply;
      @include opacity(95);
      transition: 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55) all;
    }
  }
}
.category-listing-items {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  &:nth-child(odd) {
    flex-direction: row-reverse;
  }
}
.category-column {
  background: $white;
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  &.split {
    &.horz {
      .category-row {
        flex-basis: 50%;
        &.one {
          .category-item {
            overflow: hidden;
            width: 100%;
            .category-image {
              left: 50%;
              position: absolute;
              top: 50%;
              transform: translate(-50%, -50%);
              width: 100%;
            }
          }
        }
      }
    }
    &.vert {
      flex-flow: row nowrap;
      .category-column {
        flex-basis: 50%;
      }
    }
  }
}
.category-row {
  display: flex;
  flex: 1;
  &.remaining3 {
    flex-flow: row wrap;
    .category-item {
      flex: 33%;
    }
  }
  &.remaining4 {
    flex-flow: row wrap;
    .category-item {
      flex: 25%;
    }
  }
}
.category-item {
  border-left: 1px solid $gray;
  border-bottom: 1px solid $gray;
  box-sizing: border-box;
  flex: 1;
  @extend %item-image;
  overflow: hidden;
  position: relative;
  .category-name {
    background: rgba($black, 0.35);
    color: $white;
    font-size: 2.2rem;
    font-weight: 700;
    left: 0;
    line-height: 1;
    margin: 0;
    padding: 8px 10px;
    position: absolute;
    text-shadow: 2px 2px 2px rgba($black, 0.3);
    text-transform: uppercase;
    top: 0;
  }
}

@include large-desktop {
  .category-item {
    .category-name {
      font-size: 1.8rem;
    }
  }
}

@include desktop {
  .category-item {
    .category-name {
      font-size: 1.4rem;
    }
  }
}

@include small-desktop {
  .category-item {
    .category-name {
      font-size: 1.1rem;
    }
  }
}

@include tablet {
  .category-column {
    flex: 50%;
    &.split {
      &.vert {
        flex: 100%;
      }
      &.horz {
        .category-row {
          &.one {
            .category-item {
              box-sizing: content-box;
              padding: 10% 0;
            }
          }
        }
      }
    }
  }
  .category-row {
    .category-item {
      .category-name {
        font-size: 2.2rem;
      }
    }
  }
}

@include small-tablet {
  .category-row {
    &.remaining3,
    &.remaining4 {
      .category-item {
        flex: 50%;
      }
    }
  }
  .category-item {
    .category-name {
      font-size: 2.2rem;
    }
  }
}

@include mobile {
  .category-listing-items {
    display: block;
  }
  .category-column {
    display: block;
    &.split {
      &.horz {
        .category-row {
          display: block;
          &.one {
            .category-item {
              box-sizing: border-box;
              display: block;
              padding: 0;
              .category-image {
                position: relative;
                top: inherit;
                left: inherit;
                transform: none;
              }
            }
          }
        }
      }
      &.vert {
        flex-flow: row wrap;
        .category-column {
          display: block;
        }
      }
      .category-item {
        display: block;
      }
    }
  }
  .category-row {
    display: block;
    &.remaining3,
    &.remaining4 {
      .category-item {
        display: block;
      }
    }
  }
  .category-item {
    display: block;
    .category-name {
      font-size: 2rem;
    }
  }
}

@include ie11 {
  .category-row,
  .category-column {
    @include ieFlexFix();
    flex: 1 1 auto;
  }
  .category-listing-items {
    @include ieFlexFix();
  }
  .category-item {
    @include ieFlexFix();
  }
}
