@import './category/category-listing-page';

.category {
  &.clp {
    &.page-wrapper {
      padding: 20px 0;
    }
  }
  &.plp {
    min-height: 80vh;
    overflow-x: hidden;
  }
}

@include tablet {
  .category {
    &.page-wrapper {
      &.plp {
        padding: 1.25rem 0;
      }
    }
  }
}
