.tooltip-container {
  cursor: help;
  float: right;
  position: relative;
  &:after {
    content: '';
    clear: both;
    display: block;
    float: none;
  }
  .tooltip-opener {
    &.ico-before {
      &:before {
        @include icon('\f05a');
      }
    }
    &.ico-after {
      &:after {
        @include icon('\f05a');
      }
    }
  }
  .tooltip-content {
    background: $white;
    border: 1px solid $gray90;
    border-radius: 5px;
    box-shadow: -3px 3px 15px rgba($black, 0.1), -3px 3px 5px rgba($black, 0.2);
    font-size: 0.9rem;
    max-width: 600px;
    min-width: 250px;
    padding: 0.5rem;
    position: absolute;
    text-align: left;
    width: auto;
    z-index: 1;
    &:after {
      // text-shadow: 2px 2px 5px rgba($black, 0.2);
      color: $white;
      font-size: 3rem;
      position: absolute;
    }
    &.left-side {
      right: 100%;
      &:after {
        @include icon('\f0da');
        right: -0.3rem;
      }
    }
    &.right-side {
      left: 100%;
      &:after {
        @include icon('\f0d9');
        left: -0.3rem;
      }
    }
    &.top-side {
      bottom: 100%;
      &:after {
        @include icon('\f0d7');
        bottom: -0.6rem;
      }
    }
    &.bottom-side {
      top: 100%;
      &:after {
        @include icon('\f0d8');
        top: -0.6rem;
      }
    }
    &.horz-centered {
      left: 50%;
      transform: translateX(-50%);
      &:after {
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &.vert-centered {
      top: 50%;
      transform: translateY(-50%);
      &:after {
        top: 50%;
        transform: translateY(-50%);
      }
    }
    &.horz-rt {
      right: -0.6rem;
      &:after {
        right: 2px;
      }
    }
    &.horz-lt {
      left: -0.6rem;
      &:after {
        left: 2px;
      }
    }
    &.vert-tp {
      top: 0;
    }
    &.vert-bm {
      bottom: 0;
    }
  }
}
