.modal-container.share-section-modal {
  .modal {
    .modal-window {
      .modal-body {
        .share-body-wrapper {
          box-sizing: border-box;
          padding: 10px 15px;
          .title {
            font-size: 1.5rem;
            margin: 15px 0 10px;
            text-align: center;
          }
          input {
            outline: none;
            font-size: 1rem;
          }
          .button-row {
            align-items: center;
            display: flex;
            margin: 20px 0;
            justify-content: center;
            .btn.primary.copy {
              font-size: 1.2rem;
              height: 45px;
            }
          }
        }
      }
    }
  }
}

@include mobile {
  .modal-container.share-section-modal {
    .modal {
      .modal-window {
        .modal-body {
          .share-body-wrapper {
            .title {
              font-size: 1.3rem;
            }
          }
        }
      }
    }
  }
}

@include small-mobile {
  .modal-container.share-section-modal {
    .modal {
      .modal-window {
        .modal-body {
          .share-body-wrapper {
            .title {
              font-size: 1.1rem;
            }
          }
        }
      }
    }
  }
}
