.easter-egg-wrapper {
  bottom: -30px;
  position: absolute;
  right: 20px;
  .easter-egg-opener {
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 1rem;
  }
}
.easter-egg {
  text-align: left;
  .modal {
    max-width: 320px;
    .modal-body {
      box-sizing: border-box;
      padding: 1rem;
      .title {
        margin-top: 0;
        font-size: 1.8rem;
        font-weight: bold;
        text-align: center;
      }
    }
  }
}
