// local variable defaults
$FieldHeight: 40px !default;
$FieldFontSize: 1rem !default;
$FieldBorderRadius: 4px !default;
$FieldMarginTop: 6px !default;

@import './form-actionbar';

%form-field-styling {
  box-sizing: border-box;
  border-radius: $FieldBorderRadius;
  border-width: 2px;
  border-style: solid;
  border-color: $lightgray;
  height: $FieldHeight;
  font-size: $FieldFontSize;
  outline: none;
  padding: 0.4rem 0.6rem;
  transition: 0.25s all linear;
  width: 100%;
  &:hover {
    border-color: darken($gainsboro, 35%);
  }
  &:focus,
  &:active {
    border-color: $dodgerblue;
  }
}

%disabled-field {
  border: 1px solid $gray83;
  background-color: $gray93;
  &:active,
  &:hover,
  &:focus {
    outline: none;
    background-color: $gray93;
  }
}

.form-row {
  &.btn-row {
    margin: 1rem 0;
  }
  &.align-right {
    text-align: right;
  }
  .form-item,
  .field {
    margin: 0.5rem 0;
    &.required {
      label {
        &:before {
          color: $firebrick2;
          font-size: 10px;
          margin-right: 2px;
          vertical-align: middle;
          @include icon('\f069');
        }
        span {
          vertical-align: middle;
          &.hint {
            color: $gray40;
            font-size: 0.8rem;
            margin-left: 8px;
          }
        }
      }
    }
    &.checkbox {
      label {
        display: inline-block;
        p {
          margin: 0.3rem 0;
        }
      }
      input {
        display: none;
        outline: none;
        position: absolute;
        z-index: -1;
        &:focus {
          & + label .checkbox-icon {
            color: $dodgerblue;
          }
        }
        &:checked {
          & + label .checkbox:before {
            @include icon('\f046');
          }
        }
      }
      span {
        cursor: pointer;
        vertical-align: middle;
        &.checkbox-icon {
          margin-right: 6px;
        }
        &.checkbox {
          &:before {
            @include icon('\f096');
            margin-right: 6px;
            text-align: center;
            width: 1.28571429rem;
          }
        }
      }
    }
    input,
    textarea {
      margin-top: $FieldMarginTop;
    }
  }
}

.form-title {
  border-bottom: 2px solid $gainsboro;
  color: $gray23;
  font-size: 2.2rem;
  font-weight: 700;
  margin-bottom: 15px;
  padding-bottom: 13px;
}

.password-wrapper {
  $IconSize: 1.5rem;
  position: relative;
  input {
    padding-right: calc(#{$IconSize} * 2);
  }
  span {
    &.password-icon,
    &.icon {
      color: $silver;
      cursor: pointer;
      font-size: $IconSize;
      position: absolute;
      right: calc(#{$IconSize * 0.25});
      top: calc(#{$FieldHeight} * 0.5 - (#{$IconSize} * 0.2));
      transition: 0.25s all linear;
      &:hover {
        color: darken($silver, 10%);
      }
    }
  }
}

input {
  &[type='tel'],
  &[type='text'],
  &[type='email'],
  &[type='number'],
  &[type='search'],
  &[type='password'] {
    @extend %form-field-styling;
  }
}

textarea {
  @extend %form-field-styling;
  height: 200px;
}

.select-wrapper {
  margin-top: $FieldMarginTop;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  select {
    @extend %form-field-styling;
    appearance: none;
    background-color: $white;
    overflow: hidden;
    padding-right: 24px;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
    option {
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &::-ms-expand {
      display: none;
    }
  }
  &:after {
    @include opacity(50);
    @include icon('\f0d7');
    height: auto;
    font-size: 18px;
    pointer-events: none;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
    width: auto;
    z-index: 9;
  }
  .ie9 &,
  .ie8 &,
  .ie7 &,
  .lt-ie7 & {
    &:before {
      background: $white;
      content: '';
      height: 35px;
      pointer-events: none;
      position: absolute;
      right: 3px;
      top: 2px;
      z-index: 7;
      width: 45px;
    }
  }
}
