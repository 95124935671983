.quote-request {
  .section-header {
    strong {
      font-size: 1.3rem;
      font-weight: bold;
      margin: 10px 0;
    }
  }
  .form-sections {
    display: block;
    min-height: 60vh;
    overflow: hidden;
    position: relative;
    .section {
      box-sizing: border-box;
      padding: 0 1rem;
    }
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      margin: 1rem 0;
    }
  }
  .quote-list-item {
    align-items: center;
    background-color: $white;
    border: 1px solid $silver;
    box-sizing: border-box;
    border-radius: 0.3rem;
    box-shadow: 2px 2px 6px rgba($black, 0.2);
    display: flex;
    justify-content: space-between;
    padding: 0.3rem 0.5rem;
    a {
      display: flex;
      color: $gray23;
      flex: 1;
      text-decoration: none;
      .product-info {
        box-sizing: border-box;
        padding: 8px 10px;
        flex: 1;
        span {
          display: block;
          &.product-sku {
            color: $gray47;
            font-size: 0.7rem;
          }
        }
      }
    }
    button {
      $size: 40px;
      border-radius: 50%;
      border: 1px solid transparent;
      box-sizing: border-box;
      cursor: pointer;
      height: $size;
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 1;
      padding: 2px 5px;
      text-align: center;
      transition: 0.25s ease-in-out all;
      width: $size;
      &.remove {
        background-color: $white;
        color: $gray29;
        margin-left: 10px;
        &:hover {
          background-color: lighten($lightcoral, 15%);
          border-color: $lightcoral;
        }
      }
    }
  }
}
