.bounce-in {
  animation: strech-bounce 0.9s both;
}

.bounce-out {
  animation: shrink-bounce 0.9s both;
}

@keyframes strech-bounce {
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes shrink-bounce {
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(0.65, 1.35, 1);
  }
  40% {
    transform: scale3d(1.35, 0.65, 1);
  }
  50% {
    transform: scale3d(0.75, 1.25, 1);
  }
  65% {
    transform: scale3d(1.05, 0.85, 1);
  }
  75% {
    transform: scale3d(0.85, 1.05, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
