$image_path: '../../../imgs' !default;

.download.page-wrapper {
  .releaseDate {
    color: $gray;
    margin-bottom: 0.8rem;
  }
  .button-holder {
    text-align: center;
  }
  .instructions {
    padding: 1rem 0;
    .heading {
      border-bottom: 2px solid $gray47;
      color: $gray40;
      display: block;
      font-size: 2rem;
      font-weight: 700;
      padding: 0.2rem;
    }
    .instruction {
      background-color: $white;
      border: 1px solid $gray90;
      box-sizing: border-box;
      border-radius: 7px;
      box-shadow: 0 0 25px rgba($black, 0.1), 0 0 10px rgba($black, 0.2);
      box-sizing: border-box;
      margin: 1.6rem 0;
      padding: 1rem;
      word-break: break-all;
      .content {
        .number {
          font-size: 1.2rem;
          font-weight: 700;
          margin-right: 0.5rem;
          vertical-align: middle;
        }
        .text {
          font-size: 1.1rem;
          vertical-align: middle;
        }
        .tooltip-container {
          float: right;
          .tooltip-opener {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
  .information {
    font-size: 1.3rem;
    line-height: 1.5;
  }
}
.sysreqs {
  text-align: left;
  .modal {
    max-width: 400px;
    .modal-body {
      box-sizing: border-box;
      padding: 1rem;
    }
  }
  .sysreqs-instructions {
    .heading {
      font-size: 1.5rem;
      font-weight: 600;
      margin: 0 0 0.5rem;
    }
    ul {
      font-weight: 400;
      margin: 0.5rem 0;
    }
  }
}
.download-form {
  text-align: left;
  .modal {
    max-width: 550px;
    .modal-body {
      box-sizing: border-box;
      min-height: 150px;
      padding: 1rem;
      [id^='wufoo'] {
        background-attachment: scroll;
        background-image: url('#{$image_path}/loading-spinner-blue.gif');
        background-position: center center;
        background-repeat: no-repeat;
      }
    }
  }
}
