.errors,
.success,
.warnings,
.error-msg,
.warning-msg,
.success-msg {
  padding: 10px;
  border-radius: 5px;
  box-sizing: border-box;
  p {
    margin: 2px 0;
  }
  .msg-icon {
    width: 20px;
    text-align: center;
    vertical-align: middle;
  }
  .msg-info {
    padding-left: 10px;
  }
}

.errors,
.error-msg {
  color: $gray29;
  background-color: $rosybrown1;
  border: 1px solid darken($rosybrown1, 15%);
  .msg-icon:before {
    font-size: 20px;
    color: $firebrick4;
    @include icon('\f06a');
  }
}

.warnings,
.warning-msg {
  color: $gray29;
  background-color: $wheat1;
  border: 1px solid darken($wheat1, 15%);
  .msg-icon:before {
    font-size: 20px;
    color: $carrot;
    @include icon('\f071');
  }
}

.success,
.success-msg {
  color: $gray29;
  background-color: $mint;
  border: 1px solid darken($mint, 15%);
  .msg-icon:before {
    font-size: 20px;
    color: $forestgreen;
    @include icon('\f058');
  }
}
