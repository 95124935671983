.tbl-div,
.perm-tbl-div {
  display: table;
  width: 100%;
  .tbl-row {
    display: table-row;
    .tbl-col {
      display: table-cell;
    }
  }
}
