.product {
  &.page-wrapper {
    background-color: $white;
    padding: 1rem 2rem 5rem; // 1rem 2rem 12rem
  }
}

.product-section {
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  padding: 1rem;
  .left-col {
    flex: 50%;
    .product-image {
      display: flex;
      min-height: 200px;
      flex-direction: column-reverse;
      position: relative;
      text-align: center;
      img {
        align-self: center;
        height: auto;
        flex-shrink: 0;
        max-width: 100%;
        width: auto;
      }
    }
  }
  .right-col {
    flex: 50%;
    overflow: auto;
  }
}

@include tablet {
  .product {
    .product-section {
      display: block;
      .left-col,
      .right-col {
        display: block;
      }
      .left-col {
        .product-image {
          display: block;
        }
      }
    }
  }
}
