.success-modal {
  text-align: left;
  .modal {
    max-width: 480px;
    .modal-body {
      box-sizing: border-box;
      padding: 1rem;
      .title {
        margin-top: 0;
        font-size: 1.8rem;
        font-weight: bold;
        text-align: center;
      }
      .modal-actions {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: 2rem 0 1rem;
      }
      .btn.primary.reset {
        align-items: center;
        display: flex;
        font-size: 1.4rem;
        height: 40px;
        justify-content: center;
        margin: 0 0.5rem;
        padding: 4px 10px;
      }
    }
  }
}
