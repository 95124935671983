$ButtonHeight: 50px !default;
$ButtonFontSize: 1.4rem !default;
$ButtonBorderRadius: 4px !default;

.btn {
  outline: none;
  &.primary {
    background-color: $orange1;
    border-width: 2px;
    border-style: solid;
    border-color: darken($orange1, 5%);
    border-radius: $ButtonBorderRadius;
    box-shadow: 2px 2px 8px rgba($black, 0.2);
    box-sizing: border-box;
    color: $white;
    cursor: pointer;
    height: $ButtonHeight;
    font-size: $ButtonFontSize;
    font-weight: 700;
    line-height: normal;
    padding: 4px 26px;
    text-decoration: none;
    text-shadow: 1px 1px 2px darken($orange1, 5%);
    transition: 0.25s background-color linear, 0.25s border-color linear;
    &:hover,
    &:focus {
      background-color: lighten($orange1, 8%);
    }
  }
  &.disabled,
  &[disabled] {
    background-color: lighten($lightgray, 5%) !important;
    border-color: $lightgray !important;
    cursor: default !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-shadow: 1px 1px 2px $lightgray !important;
    &:hover,
    &:focus {
      background-color: lighten($lightgray, 5%) !important;
    }
  }
  .loading-spinner {
    min-width: 70px;
  }
}

@include mobile {
  .btn {
    width: 100%;
  }
}
