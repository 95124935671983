// %secondary {
// 	background-color: $silver;
// 	border-color: darken($silver, 7%);
// 	margin-right: 2rem;

// 	&:hover {
// 		background-color: lighten($silver, 5%);
// 	}
// }

// %secondaryOutlined {
// 	background-color: $offwhite;
// 	border-color: $gray60;

// 	&:hover {
// 		background-color: $white;
// 		border-color: darken($gray60, 10%);
// 	}
// }

.action-bar {
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;

  .group-btns {
    &.right {
      margin-left: auto;
    }
  }

  // button {
  // 	border-radius: 0.4rem;
  // 	border-style: solid;
  // 	border-width: 1px;
  // 	box-sizing: border-box;
  // 	color: $gray23;
  // 	cursor: pointer;
  // 	height: auto;
  // 	font-size: 0.9rem;
  // 	font-weight: 600;
  // 	min-height: 40px;
  // 	min-width: 80px;
  // 	padding: 5px 8px;
  // 	text-align: center;
  // 	transition: 0.4s ease-in-out background, 0.4s ease-in-out border;

  // &.primary,
  // &[type="submit"] {
  // 	background-color: $PrimaryAction;
  // 	border-color: darken($PrimaryAction, 5%);
  // 	color: $white;

  // 	&:hover {
  // 		background-color: lighten($PrimaryAction, 10%);
  // 	}
  // }

  // &.nextbtn,
  // &.secondaryOutline {
  // 	@extend %secondaryOutlined;
  // }

  // &.prevbtn,
  // &.secondary {
  // 	@extend %secondary;
  // }

  // &.remove {
  // 	background-color: $firebrick1;
  // 	border-color: darken($firebrick1, 12%);
  // 	color: $white;

  // 	&:hover {
  // 		background-color: lighten($firebrick1, 10%);
  // 	}
  // }

  // &.share {
  // 	@extend %secondaryOutlined;

  // 	span {
  // 		&:before {
  // 			@include icon("\f1e0");
  // 			font-weight: 500;
  // 			margin-right: 5px;
  // 		}
  // 	}
  // }

  // &.reset {
  // 	span {
  // 		&:before {
  // 			@include icon("\f021");
  // 			font-weight: 500;
  // 			margin-right: 5px;
  // 		}
  // 	}
  // }

  // &.clear-all {
  // 	background-color: $firebrick1;
  // 	border-color: darken($firebrick1, 12%);
  // 	color: $white;
  // 	margin-right: 2rem;

  // 	span {
  // 		&:before {
  // 			@include icon("\f1f8");
  // 			font-weight: 500;
  // 			margin-right: 5px;
  // 		}
  // 	}

  // 	&:hover {
  // 		background-color: lighten($firebrick1, 10%);
  // 	}
  // }
  // }

  &.admin {
    margin: 1rem 0;

    button {
      margin: 0 0.5rem;
      height: 40px;
      font-size: 1rem;
      min-width: 100px;
      padding: 0 1rem;
    }
  }

  .title-bar & {
    margin: 0;
  }
}

@include mobile {
  .action-bar {
    .group-btns {
      display: flex;
      flex: 1 1 50%;

      button {
        flex: 1;
      }
    }

    // button {
    // 	flex: 1 1 50%;

    // 	&.prevbtn,
    // 	&.clear-all {
    // 		margin-right: 2rem;
    // 	}
    // }
  }
}
