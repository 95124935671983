// local mixins
@mixin generate-columns($cols: 12, $prefix: '', $value: 0) {
  $ColNumber: if(is-number($cols), $cols, 12);
  @while $ColNumber > 0 {
    .col-#{$prefix}span#{$ColNumber} {
      @include col-width(if($value != 0 and is-number($value), $value, $ColNumber));
    }
    $ColNumber: $ColNumber - 1;
  }
}

.flex-row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  width: 100%;
  &.item-bln {
    align-items: baseline;
  }
  &.item-btm {
    align-items: flex-end;
  }
  &.item-cnt {
    align-items: center;
  }
  &.item-str {
    align-items: stretch;
  }
  &.item-top {
    align-items: flex-start;
  }
  &.cont-lft {
    justify-content: flex-start;
  }
  &.cont-rgt {
    justify-content: flex-end;
  }
}
.col {
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.inl-block {
  display: inline-block;
}

@include generate-columns(11);
.col-full {
  @include col-width(12);
}

// Perminate columns
@include generate-columns(11, 'pm');

@include tablet {
  @include generate-columns(12, '', 12);
}
